import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { Button } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconTableImport } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import UnitBadge from '../../../../components/badges/UnitBadge';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import CounterUserService from '../../../../services/CounterUserService';
import type {
  CounterTypeResponse,
  CounterUserResponse,
  Division,
} from '../../../../types/types';
import { hasPermission, isAccountant } from '../../../../utils/authorization';
import { getTranslatedKey } from '../../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import {
  ALL,
  ValidateHolidayCounter,
} from '../../../../variables/BuiltInPermissions';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import EditUserCounterForm from './EditUserCounterForm';

export type UsersCountersData = {
  userId: string;
  userFullname: string;
  division?: Division;
  countersUsers?: CounterUserResponse[];
};

type Props = {
  divisions: Division[];
  data?: UsersCountersData[];
  counterTypeResponses?: CounterTypeResponse[];
  companyId: string;
};

const componentName = 'UsersCounters';

export default function UsersCounters({
  data,
  counterTypeResponses,
  divisions,
  companyId,
}: Props) {
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const counterTypes = useMemo(
    () =>
      counterTypeResponses
        ?.map((item) => item.counterType)
        .filter((elt) => elt.active),
    [counterTypeResponses]
  );

  const { mutate: exportUsersCounters, isLoading } = useMutation({
    mutationFn: () => CounterUserService.exportUsersCounters(companyId),
    onSuccess: (data) => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-users-counters-success',
        message: t('w.success'),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-users-counters-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      }),
  });

  const rightCustomActions = useMemo(
    () => [
      <Button
        key={'export-button'}
        disabled={
          (!hasPermission(
            { permission: ValidateHolidayCounter, scope: ALL },
            user
          ) &&
            !isAccountant(user, companyId)) ||
          isLoading
        }
        leftSection={<IconTableImport />}
        variant={'filled'}
        loading={isLoading}
        onClick={() => exportUsersCounters()}
      >
        {t('w.export')}
      </Button>,
    ],
    []
  );

  const columns: DataTableColumn<UsersCountersData>[] = useMemo(() => {
    const result: DataTableColumn<UsersCountersData>[] = [
      {
        accessor: 'userFullname',
        title: t('w.employee'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, userFullname }) => (
          <GoToUserButton userId={userId} userFullname={userFullname} />
        ),
      },
      {
        accessor: 'division.name',
        title: t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name} />,
      },
    ];

    counterTypes?.forEach((counterType) => {
      result.push({
        accessor: counterType.id,
        title: getTranslatedKey(t, counterType.key),
        sortable: true,
        ellipsis: true,
        render: ({ countersUsers, userId, division }) => (
          <EditUserCounterForm
            disabled={division?.name === NO_WORK_UNIT}
            key={counterType.id}
            userId={userId}
            counterType={counterType}
            value={
              countersUsers?.find(
                (item) => item.counterTypeId === counterType.id
              )?.value
            }
            date={
              countersUsers?.find(
                (item) => item.counterTypeId === counterType.id
              )?.date
            }
          />
        ),
      });
    });

    return result;
  }, [counterTypes]);

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'division.name', direction: 'asc' },
    search: {
      accessor: 'userFullname',
      label: t('w.employee'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'division.name',
          label: t('w.unit'),
          choices: divisions.map((division) => ({
            value: division.name,
            label:
              division.name === NO_WORK_UNIT
                ? t('NO_WORK_UNIT')
                : division.name,
          })),
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      idAccessor="userId"
      pinFirstColumn
      rows={data ? data : []}
      options={options}
      lang={lang}
      withTableBorder={false}
      columns={columns}
      fetching={!data || !counterTypeResponses}
      rightCustomActions={rightCustomActions}
      height={'calc(100vh - 200px)'}
    />
  );
}
