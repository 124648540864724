import { useQueries, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';

import { useAppBar } from '../../../../contexts/AppBarProvider';
import { useAuth } from '../../../../contexts/AuthProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import CounterTypeService from '../../../../services/CounterTypeService';
import CounterUserService from '../../../../services/CounterUserService';
import { hasRole, isAccountant } from '../../../../utils/authorization';
import { ADMINISTRATOR } from '../../../../variables/BuiltInRoles';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import UsersCounters, { UsersCountersData } from './UsersCounters';

type Props = {
  companyId: string;
  withUnitFilter?: boolean;
  selectedDivisionIds?: string[];
};

export default function HolidayCounters({
  companyId,
  withUnitFilter = true,
  selectedDivisionIds,
}: Props) {
  const { divisions } = useFetchDivisions({ companyId });
  const {
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
    appBarSelectedDivisions,
  } = useAppBar();
  const { user } = useAuth();

  useEffect(() => {
    if (!withUnitFilter) return;

    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, [withUnitFilter]);

  useEffect(() => {
    if (!withUnitFilter) return;
    buildAppbarDivisions();
  }, [hasRole(ADMINISTRATOR, user), divisions, isAccountant(user, companyId)]);

  function buildAppbarDivisions() {
    let _divisions = divisions.filter(
      (division) => division.name !== NO_WORK_UNIT
    );
    if (!hasRole(ADMINISTRATOR, user) || !isAccountant(user, companyId)) {
      _divisions = _divisions.filter(
        (division) =>
          division.idOfN1 === user.id ||
          division.idOfN2 === user.id ||
          division.idOfAssistant === user.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }
  const divisionIds: string[] = useMemo(() => {
    if (selectedDivisionIds) return selectedDivisionIds;
    if (!divisions) return [];

    if (!appBarSelectedDivisions?.length) {
      return divisions.map((item) => item.id);
    }

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions, selectedDivisionIds]);

  const { data: users } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'divisionId',
        'valid',
      ]),
  });
  const usersCountersQueries = useQueries({
    queries: users
      ? users
          .filter((item) => item.valid)
          .map((user) => {
            return {
              queryKey: ['CounterUserService.getByUser', user.id],
              queryFn: () => CounterUserService.getByUser(user.id),
            };
          })
      : [],
  });
  const data: UsersCountersData[] | undefined = useMemo(() => {
    const isLoadingUsersCountersQueries = Boolean(
      usersCountersQueries.find((item) => item.isLoading)
    );

    if (isLoadingUsersCountersQueries || !users) return undefined;

    const dataArray = usersCountersQueries.map((item) => item.data);

    if (!dataArray?.length) return [];

    const result: UsersCountersData[] = [];

    users
      .filter((item) => item.valid && divisionIds.includes(item.divisionId))
      .forEach((user) => {
        const countersUsers = dataArray.find(
          (arr) => arr?.[0].userId === user.id
        );
        const division = divisions.find((item) => item.id === user.divisionId);

        result.push({
          userId: user.id,
          userFullname: user.fullname,
          division,
          countersUsers,
        });
      });

    return result.sort((a, b) => a.userFullname.localeCompare(b.userFullname));
  }, [usersCountersQueries, users, divisionIds]);

  const { data: counterTypeResponses } = useQuery({
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  return (
    <UsersCounters
      data={data}
      counterTypeResponses={counterTypeResponses}
      divisions={divisions}
      companyId={companyId}
    />
  );
}
